* {
	box-sizing: border-box;
  }
  
  body {
	margin: 0;
	font-family: "linuxlib";

	
  }
  
  .container {
	margin: 0;

  }
  
  .nav {
	background-color: none;
	color: black;
	display: flex;
	justify-content: space-between;
	align-items: stretch;
	gap: 2rem;
	padding: 0 1rem;
	font-size: 18px;

	position: fixed;
	top: 1.3rem;
    left: 1.3rem;


    right: 1rem;
    z-index: 1000;
  }

  @media (max-width: 500px) {
	.nav { 
		background-color: none;
		color: black;
		display: flex;
		justify-content: space-between;
		align-items: stretch;
	
		padding: 0 0rem;
		font-size: 18px;
	
		position: fixed;
		top: 1rem;
	
	
		z-index: 1000;
	}
  }

  .linkName {
	text-decoration: none;
	color: black;
	list-style: none;
  }
  .site-title {
	font-size: 18px;
  }

  .capital {
	font-family: "glamore";
	font-size: 20px;
	margin-left: 0.4rem;
  }

  .deco {
	font-size: 11px;
	color:rgb(73, 61, 97);
  }



  .blogIcon {
	  display: block;
	  margin-left: auto;
	  margin-right: auto;
	  width : 30px;
	}

  .nav2 {
	background-color: none;
	color: black;
	display: flex;
	justify-content: space-between;
	align-items: stretch;
	gap: 2rem;
	padding: 0 1rem;
	font-size: 18px;
	position: fixed;
    bottom: 1.5rem;
    left: 1.6rem;
    right: 0;
    z-index: 1000;
  }

  @media (max-width: 500px) {
	.nav2 { 
		background-color: none;
		color: black;
		display: flex;
		justify-content: space-between;
		align-items: stretch;

		padding: 0 0rem;
		font-size: 18px;
		position: fixed;
		bottom: 1.3rem;
	
		right: 0;
		z-index: 1000;
	}
  }
  
  .nav2 ul {
	padding: 0;
	margin: 0;
	list-style: none;
	display: flex;
	gap: 3rem;
  }

  @media (max-width: 500px) {
	.nav2 ul{ 
		padding: 0;
		margin: 0;
		list-style: none;
		display: flex;
		gap: 1rem;
	}
  }
  
  .nav2 a {
	color: inherit;
	text-decoration: none;
	height: 100%;
	display: flex;
	align-items: center;
	padding: .25rem;
  }

 .nav2 a span {
	display: inline-block;
	transition: transform 0.6s ease; /* Smooth transition for each letter */
  }
  
  .nav2 a:hover {
	transform: translateY(-2.5px);
  }
  /* Hover effect: rotate each letter */
  .nav2 a:hover span {
	transform: rotate(180deg); /* Rotate each letter by 10 degrees */

  }


  .homeImg {
	position: absolute;
	left: calc(50% - 200px);
	margin-top:160px;
	width : 400px;
  }

  @media (max-width: 400px) {
	.homeImg {
	left:0px;
	width: 100%; /* Adjust width on smaller screens */
	max-width: 400px; /* Max width to prevent scaling too large */
	}	
  }

  /* -------------------------- bio ------------------------ */

  .bio-background {
	background: url('images/frameline2.webP') center top repeat-y;
	background-size: 11px; /* Keeps the original image size */
	background-position: calc(50% + 400px) top;
	height: 100vh; /* Ensures the div takes the full viewport height */
	width: 100%; /* Full width */
	position: relative;
	
	opacity: 80%;
	z-index: -2;
}

  @media (max-width: 700px) {
	.bio-background {
		background: url('images/frameline2_rotate.webP') center top repeat-x;
		background-size: 360px; /* Keeps the original image size */
		top:32rem;
		height: 8vh; /* Ensures the div takes the full viewport height */
		width: 100%; /* Full width */
		position: relative;
		opacity: 80%;
		}	
  }

  .bioImg {
	position: absolute;
	opacity:30%;
	left: calc(50% - 200px);
	margin-top:160px;
	width : 400px;
	z-index: -1;
  }
  @media (max-width: 400px) {
	.bioImg {
	left:0px;
	width: 100%; /* Adjust width on smaller screens */
	max-width: 400px; /* Max width to prevent scaling too large */
	z-index: -1;	
}	
  }

  .bioImgBio {
	position: absolute;
	opacity:30%;
	left: calc(50% - 200px);
	margin-top:160px;
	width : 400px;
	
  }
  @media (max-width: 400px) {
	.bioImgBio {
	left:0px;
	width: 100%; /* Adjust width on smaller screens */
	max-width: 400px; /* Max width to prevent scaling too large */

}	
  }


  .bioFrame {
	position: absolute;
	padding-top: 12px;
	padding-left: 1rem;
	width: 550px;
	animation: fade-in 2s
  }

  @media (max-width: 510px) {
	.bioFrame {
	left: calc(50% - 280px);	
	}
  }

  .bioText {
		position: absolute;
		font-size: 18px;
		top: 8rem;
		left: calc(50% - 360px);
		width: 725px;
		line-height: 1.4em;
		text-align: left;
		align-items: center;
  }

  @media (max-width: 725px) {
	.bioText {
	left:20px;
	text-align: justify;
	width: 90%;
	max-width: 90%;
	font-size: 18px;
	}
  }


  /* -------------------------- exhibition ------------------------ */

  .exhibitions-background {
	background: url('images/frameline3.webP') center top repeat-x;
	background-size: 530px; /* Keeps the original image size */
	top:34rem;
	height: 10vh; /* Ensures the div takes the full viewport height */
	width: 100%; /* Full width */
	position: relative;
  }

  @media (max-height: 590px) {
	.exhibitions-background {
		top:20rem;
		}	
  }

  .exhibitionText {
		
		font-size: 18px;
		position: absolute;
		top: 7rem;
		left: 16rem;
		text-align: left;
		text-indent: 1rem;
		
  }

  @media (max-width: 500px) {
	.exhibitionText {
		align-content: center;
		left: 1.8rem;
		}	
  }

  .exhibitionStar {
	font-size: 9px;
  }


  .infoExhibition {
	font-size: 15px;
  }
   /* -------------------------- contact ------------------------ */
  .contactFrame {
		position: inherit;
		top:-1rem;
		left:-2rem;
		width: 370px;
		z-index: -1;
  }

   .contactText {
		font-size: 18px;
		position: absolute;
		top: 6rem;
		line-height: 1.4em;
		right: 31rem;
  }

@media (max-width: 700px) {
	.contactText {
		right: 9.2rem;
	}
}

.contactPicto {
	font-size:11px;
	
}

.linkInsta {
	text-decoration: none;
	color:black;
}
 
  /* -------------------------- shop ------------------------ */

.shop {
	display: flex;
	align-items: center;
	justify-content: space-around;
	flex-direction: column;
	animation: fade-in 2s;
}

.img1 {
	display :block;
	margin-top: 24px;
	width: 600px;
}

@media (max-width: 600px) {
	.img1 {
	margin-top: 60px;
	width: 100%;
	max-width: 100%;
	}
}

.title1 {
	text-align: left;
	width: 600px;
}

@media (max-width: 600px){
	.title1 {
		width:100%;
		max-width:100%;
		text-align: center;
	}
}

.title2 {
	text-align: left;
	width: 600px;
	height:250px;
}

@media (max-width: 600px){
	.title2 {
		width:100%;
		max-width:100%;
		height:100px;
		text-align: center;
	}
}

.img2 {
	display: block;
	margin-top:50px;
	width : 600px;
}

@media (max-width: 600px) {
	.img2 {
	width: 100%;
	max-width: 100%;
	}
}

.img3 {
	display: block;
	margin-top:50px;
	width : 600px;
}

@media (max-width: 600px) {
	.img3 {
	width: 100%;
	max-width: 100%;
	}
}

.img4 {
	display: block;
	margin-top:50px;
	width : 600px;
}

@media (max-width: 600px) {
	.img4 {
	width: 100%;
	max-width: 100%;
	}
}

.img5 {
	display: block;
	margin-top:50px;
	width : 600px;
}

@media (max-width: 600px) {
	.img5 {
	width: 100%;
	max-width: 100%;
	}
}

.img6 {
	display: block;
	margin-top:50px;
	width : 600px;
}

@media (max-width: 600px) {
	.img6 {
	width: 100%;
	max-width: 100%;
	}
}

.img7 {
	display: block;
	margin-top:50px;
	width : 600px;
}

@media (max-width: 600px) {
	.img7 {
	width: 100%;
	max-width: 100%;
	}
}

.img8 {
	display: block;
	margin-top:50px;
	width : 600px;
}

@media (max-width: 600px) {
	.img8 {
	width: 100%;
	max-width: 100%;
	}
}

.img9 {
	display: block;
	margin-top:50px;
	width : 600px;
}

@media (max-width: 600px) {
	.img9 {
	width: 100%;
	max-width: 100%;
	}
}

.img10 {
	display: block;
	margin-top:50px;
	width : 600px;
}

@media (max-width: 600px) {
	.img10 {
	width: 100%;
	max-width: 100%;
	}
}

.img11 {
	display: block;
	margin-top:50px;
	width : 600px;
}

@media (max-width: 600px) {
	.img11 {
	width: 100%;
	max-width: 100%;
	}
}

.img12 {
	display: block;
	margin-top:50px;
	width : 600px;
}

@media (max-width: 600px) {
	.img12 {
	width: 100%;
	max-width: 100%;
	}
}

.img13 {
	display: block;
	margin-top:50px;
	width : 600px;
}

@media (max-width: 600px) {
	.img13 {
	width: 100%;
	max-width: 100%;
	}
}

.img14 {
	display: block;
	margin-top:50px;
	width : 600px;
}

@media (max-width: 600px) {
	.img14 {
	width: 100%;
	max-width: 100%;
	}
}

.img15 {
	display: block;
	margin-top:50px;
	width : 600px;
}

@media (max-width: 600px) {
	.img15 {
	width: 100%;
	max-width: 100%;
	}
}

.img16 {
	display: block;
	margin-top:50px;
	width : 600px;
}

@media (max-width: 600px) {
	.img16 {
	width: 100%;
	max-width: 100%;
	}
}

.img17 {
	display: block;
	margin-top:50px;
	width : 600px;
}

@media (max-width: 600px) {
	.img17 {
	width: 100%;
	max-width: 100%;
	}
}

.img18 {
	display: block;
	margin-top:50px;
	width : 600px;
}

@media (max-width: 600px) {
	.img18 {
	width: 100%;
	max-width: 100%;
	}
}

.img19 {
	display: block;
	margin-top:50px;
	width : 600px;
}

@media (max-width: 600px) {
	.img19 {
	width: 100%;
	max-width: 100%;
	}
}

.img20 {
	display: block;
	margin-top:50px;
	width : 600px;
}

@media (max-width: 600px) {
	.img20 {
	width: 100%;
	max-width: 100%;
	}
}

.img21 {
	display: block;
	margin-top:50px;
	width : 600px;

}

@media (max-width: 600px) {
	.img21 {
	width: 100%;
	max-width: 100%;
	}
}

.moving-char {
	position: absolute; /* Allows the character to move */
	font-size: 1rem; /* Adjust the size of the character */
	color: rgb(209, 173, 173); /* You can change the color */
	top: 0;
	left: 0;
	overflow: hidden; /* Ensure no overflow occurs */
	margin: 0; /* No margins to ensure it stays within bounds */
	padding: 0; /* No padding for the same reason */
	opacity: 50%;
  }

  .container {
	animation: fade-in 2s;
  }

  .subContainer {
	animation: fade-in 2s;
  }
  
  @keyframes fade-in {
	from {
	  opacity: 0;
	}
	to {
	  opacity: 100;
	}
  }

  .arrowShop {
	font-size : 11px;
 	color:rgb(131, 131, 131);
  }

  .arrowShop a:hover span {
	margin-top: 20px; 
  }
  
  .linkToBuy {
	text-decoration: none;
	text-align: end;
	color:black;
  }

 .title1 a span {
	display: inline-block;
	transition: transform 0.6s ease; /* Smooth transition for each letter */
  }
  
  /* Hover effect: rotate each letter */
.title1 a:hover span {
	transform: rotate(180deg) translateY(1.6px); /* Rotate each letter by 10 degrees */ 
}

  .title2 a span {
	display: inline-block;
	transition: transform 0.6s ease; /* Smooth transition for each letter */
  }
  
  /* Hover effect: rotate each letter */
.title2 a:hover span {
	transform: rotate(180deg) translateY(1.6px); /* Rotate each letter by 10 degrees */
	}


	.carousel-container {
		position: relative;
		width: 100%;
		max-width: 600px; /* Adjust based on your design */
		margin: 0 auto;
		margin-top:50px;
	  }
	  
	  .carousel {
		position: relative;
		overflow: hidden;
	  }
	  
	  .carousel-image {
		width: 100%;
		height: auto;
	  }
	  
	
	  .carousel-arrow {
		position: absolute;
		top: 50%;
		background: none;
		border: none;
		cursor: pointer;
		font-size: 2rem;
		z-index: 10;

	  }
	  
	  .carousel-arrow.left-arrow {
		left: 0px;
		animation: nudge 2.5s infinite;
	  }
	  
	  .carousel-arrow.right-arrow {
		right: 0px;
		animation: nudge-right 3.5s infinite;
	  }
	  
	  @keyframes nudge {
		0%, 100% {
		  transform: translateY(-50%) translateX(0);
		}
		50% {
		  transform: translateY(-50%) translateX(-5px); /* Left nudge for the left arrow */
		}
	  }
	  

	  
	  @keyframes nudge-right {
		0%, 100% {
		  transform: translateY(-50%) translateX(0);
		}
		50% {
		  transform: translateY(-50%) translateX(5px); /* Right nudge for the right arrow */
		}
	  }	  


  /* -------------------------- fonts ------------------------ */
@font-face {
    font-family: 'linuxlib';
    src: url('font/LinLibertine_R.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'moxa';
    src: url('font/moxa_bestine/MOXA\ Bestine.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'british_green';
    src: url('font/british_green/British\ Green.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'glamore';
    src: url('font/glamore/Glamore.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}